@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* > * {
  font-family: "Inter", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.section {
  @apply py-10 grid grid-cols-12;
}

.section-content {
  @apply col-start-2 col-end-12;
}

.subheader {
  @apply font-bold text-4xl text-center text-tertiary select-none;
}

.paragraph {
  @apply text-sm text-base font-normal text-black/60 select-none w-fit;
}

.benefits {
  @apply flex flex-col justify-center gap-2 text-slate-50 hover:text-white transform hover:scale-105 transition ease-out duration-500 lg_tablet:flex-row lg_tablet:gap-0;
}

.card {
  @apply grid grid-cols-12 grid-rows-1 overflow-hidden rounded-3xl shadow-lg transform hover:scale-105 transition ease-out duration-500;
}

.card-img {
  @apply col-start-1 col-end-13 row-start-1 object-cover h-full w-full bg-gray-300;
}

.card-info {
  @apply col-start-1 col-end-13 py-5 bg-secondary text-tertiary text-center;
}

.btn-white {
  @apply bg-white cursor-pointer transition ease-out duration-500 rounded-full shadow-2xl text-primary font-bold text-xs p-4 text-center hover:opacity-90 sm_tablet:text-lg;
}

.btn-primary {
  @apply bg-secondary cursor-pointer transition ease-out duration-500 rounded-full text-primary font-bold text-lg p-4 text-center hover:opacity-90;
}

.footer-list-header {
  @apply font-bold text-gray-300 hover:cursor-none text-xs sm_tablet:text-sm lg_tablet:text-xl;
}

.footer-list-group {
  @apply my-2 text-xs lg_tablet:text-sm flex flex-col;
}

.footer-list-item {
  @apply font-semibold text-slate-400 hover:text-slate-200 text-xs cursor-pointer;
}

.social-icons {
  @apply text-slate-400 hover:cursor-pointer hover:text-slate-200;
}

.form {
  @apply flex flex-col gap-y-5 justify-center;
}

.form-input {
  @apply border border-slate-300 w-full m-0 rounded-md py-3 px-3 text-sm shadow-sm placeholder-slate-500 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary;
}

.mobile-routes {
  @apply flex gap-2 items-end;
}

.overlay {
  @apply absolute inset-0 bg-black bg-opacity-50
}